/* General Styles */
html, body {
  font-family: "Google Sans", "Roboto", "Noto Sans Myanmar UI", arial, sans-serif !important;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

body {
  background-color: #f68f8f;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
}



/* Logo */
.App-logo {
  height: 40px;
  margin-right: 10px;
  pointer-events: none;
  display: inline-block;
  vertical-align: middle;
}

/* Navigation */

header {
  padding: 10px 0;
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.brand-link {
  text-decoration: none;
  color: inherit;
}

/* Sidebar Navigation */
.side-nav {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 250px;
  transition: all 0.3s ease;
  overflow-x: hidden;
  white-space: nowrap;
  height: 100vh;
  padding: 1rem;
  background-color: #f1f3f4;
  color: #5f6368;
  display: flex;
  flex-direction: column;
}

.side-nav.collapsed {
  width: 80px;
  padding: 1rem 1rem;
}

.nav-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px; /* Add default padding */
}

.side-nav.collapsed .nav-content {
  padding: 0; /* Remove padding when collapsed */
}

.nav-item {
  display: flex;
  align-items: center;
}

.nav-link.collapsed {
  padding: 0.5rem 1rem;
}

.nav-brand {
  text-decoration: none !important;
  color: #202124;
  font-size: 1.25rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.nav-brand.collapsed {
  padding: 0.5rem 1rem;
}

.side-nav .nav-link {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  color: #5f6368;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.side-nav .nav-link:hover,
.side-nav .nav-link:focus {
  background-color: #e8eaed;
  color: #202124;
  border-radius: 10px;
}

.side-nav .nav-icon {
  margin-right: 10px;
  font-size: 1.2em;
}

.side-nav.collapsed .nav-icon {
  margin-right: 0;
}

.side-nav .nav-text {
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.side-nav.collapsed .nav-text {
  opacity: 0;
  visibility: hidden;
  width: 0;
  height: 0;
  overflow: hidden;
}

.nav-toggle {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  background: none;
  border: none;
  font-size: 1.2em;
}

.side-nav.collapsed .nav-toggle {
  right: 50%;
  transform: translateX(50%);
}

.side-nav .App-logo {
  height: 30px;
  width: auto;
}

.side-nav .btn-curved {
  margin-top: auto;
}

.link-container {
  padding-top: 1rem;
}

.link-container.collapsed {
  padding-top: 1rem;
}

.btn-logout {
  margin-top: auto;
  align-self: center;
  width: 100%;
  color: #202124 !important;
  background-color: #e8eaed !important;
  border-radius: 10px;
  border-color: #000000 !important;
  transition: all 0.3s ease;
}

.btn-logout.btn-collapsed {
  padding: 0.5rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.side-nav.collapsed .btn-logout {
  margin-bottom: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  padding: 0.5rem;
  justify-content: center;
}

.side-nav.collapsed .btn-logout .nav-text {
  display: none;
}

/* Main Content */
.app-container {
  display: flex;
  transition: margin-left 0.3s ease;
  margin-left: 250px;
}

.app-container.nav-collapsed {
  margin-left: 80px;
}

.main-content {
  flex: 1;
  transition: margin-left 0.3s ease;
  overflow-y: auto;
  width: 100%;
}

.main-content.nav-collapsed {
  margin-left: 0;
}

.nav-logo-image {
  height: 30px;
  width: auto;
  margin-right: 5px;
  margin-left: 5px;
}

@media (min-width: 768px) {
  .app-container {
    margin-left: 250px;
  }

  .app-container.nav-collapsed {
    margin-left: 80px;
  }
}

@media (max-width: 768px) {
  .app-container {
    margin-left: 80px;
  }

  .side-nav {
    width: 80px;
  }

  .side-nav .nav-text {
    display: none;
  }

  .btn-logout {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    padding: 0.5rem;
    background-color: #e8eaed;
    color: #202124;
  }
}

/* Add this to your App.css */
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: transform 500ms,opacity 500ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: transform 500ms,opacity 500ms;
}

/* Adjustments for mobile responsiveness */
@media (max-width: 480px) {
  .side-nav {
    width: 100%; /* Full width for side-nav on small screens */
    z-index: 1000; /* Ensure side-nav is on top */
  }

  .side-nav.expanded {
    width: 100%; /* Ensure full width when expanded */
  }

  .app-container {
    margin-left: 0; /* Remove left margin for app-container */
    z-index: 1; /* Set lower z-index for app-container */
  }

  .nav-toggle {
    font-size: 1.5em; /* Increase toggle button size */
    z-index: 1001; /* Ensure toggle button is on top */
  }

  .btn-logout {
    width: 100%; /* Full width for logout button */
    padding: 1rem; /* Increase padding for better touch targets */
    z-index: 1001; /* Ensure logout button is on top */
  }

  .nav-text {
    display: inline !important; /* Force display of text */
    font-size: 1rem; /* Adjust font size */
    z-index: 1001; /* Ensure text is on top */
  }

  .side-nav.expanded .nav-text {
    display: inline !important; /* Force display of text when expanded */
  }

  .side-nav.collapsed .nav-text {
    display: none !important;
  }

  .side-nav.collapsed .btn-logout {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    padding: 0.5rem;
  }
}

/* Animations */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Dark Mode Styles */
body.dark-mode {
  background-color: #131314;
  color: #ffffff;
}

body.dark-mode .side-nav {
  background-color: #2c2c2c;
  color: #ffffff;
}

body.dark-mode .nav-link {
  color: #ffffff;
}

body.dark-mode .nav-link:hover,
body.dark-mode .nav-link:focus {
  background-color: #131314;
  color: #ffffff;
}

body.dark-mode .btn-logout {
  background-color: #131314 !important;
  color: #ffffff !important;
}

body.dark-mode .main-content {
  background-color: #131314;
}

body.dark-mode .send-button {
  filter: invert(1);
}

body.dark-mode .nav-brand {
  color: #ffffff;
}

/* Additional Dark Mode Styles */
body.dark-mode .App {
  color: #ffffff; /* Ensure text is visible */
}

body.dark-mode .nav-item {
  color: #ffffff; /* Ensure nav items are visible */
}

body.dark-mode .nav-toggle {
  color: #ffffff; /* Ensure toggle button is visible */
}

/* Ensure all buttons are styled for dark mode */
body.dark-mode .btn-curved {
  background-color: #2c2c2c; /* Button background */
  color: #ffffff; /* Button text color */
}

/* Font Imports */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:400,300,500,700,900');
@import url('https://fonts.googleapis.com/css?family=Noto+Sans:400,700');
@import url('https://fonts.googleapis.com/css?family=Google+Sans:400,500,700');
