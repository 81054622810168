/* Button Styles */
.button-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 0;
}

.button-card {
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 15px;
  margin: 10px;
  width: 250px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button-card:hover {
  background-color: #e0e0e0;
}

.prompt-input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-top: 20px;
}

/* search page */
.search-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

/* Chat Styles */
.chat-window {
  height: 100%;
  overflow-y: auto;
  padding: 20px;
  border-radius: 10px; /* Rounded corners */
}

.message-container-initial {
  display: flex;
  flex-direction: column;
  padding-left: 16rem;
  padding-right: 16rem;
  padding-top: 4rem;
}

.message-container {
  display: flex;
  flex-direction: column;
  padding-left: 10rem;
  padding-right: 10rem;
}


.message {
  max-width: 100%; /* Allow messages to take full width */
  padding: 10px 15px;
  border-radius: 18px;
  line-height: 1.4;
  position: relative;
  align-self: flex-start; /* Align all messages to the left */
}

.message.user {
  color: rgb(0, 0, 0);
}

.dark-mode .message.user {
  color: #ffffff;
}


.dark-mode .message.assistant {
  background-color: #3a3a3a; /* Dark mode assistant message color */
  color: #e0e0e0;
}

.message-content {
  display: flex;
  align-items: flex-start;
}

.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: rgb(8, 8, 8);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: 10px;
  flex-shrink: 0;
  background-color: #c8c8c8;
}

.dark-mode .avatar {
  color: rgb(255, 255, 255);
  background-color: #3a3a3a;
}

.timestamp {
  font-size: 0.75em;
  color: #999;
  margin-top: 5px;
  display: block;
  text-align: left;
  padding-left: 40px;
}

.dark-mode .timestamp {
  color: #bbb;
}

.chat-input-form {
  width: 100%;
  position: relative;
  justify-content: center;
}

.chat-input {
  width: 100%;
  border-radius: 20px;
  padding: 10px 20px;
  padding-right: 40px;
  font-size: 16px;
  height: auto;
  max-height: 160px;
  overflow-y: auto;
  resize: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.send-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.send-button img {
  width: 24px;
  height: 24px;
}

.send-button:hover {
  background-color: #0056b3;
}

/* Typography */
.greeting {
    font-size: 3.5rem; /* Increased size */
    background: linear-gradient(90deg, #6a82fb, #fc5c7d); /* Gradient effect */
    -webkit-background-clip: text; /* Clip background to text */
    -webkit-text-fill-color: transparent; /* Make text transparent */
    margin-bottom: 10px; /* Space below */
  }
  
  .sub-greeting {
    font-size: 2rem; /* Increased size */
    color: #b0b0b0; /* Light gray for better contrast */
    margin-bottom: 30px; /* Space below */
  }

  .footer-text {
    font-size: 0.8rem; /* Smaller footer text size */
  }

/* Dark Mode Styles */
.dark-mode {
  background-color: #1a1a1a;
  color: #e0e0e0;
}

.dark-mode .button-card {
  background-color: #2a2a2a;
  color: #e0e0e0;
}

.dark-mode .button-card:hover {
  background-color: #3a3a3a;
}

.dark-mode .prompt-input {
  background-color: #2a2a2a;
  border-color: #444;
  color: #e0e0e0;
}

.dark-mode .message.assistant {
  background-color: #2a2a2a;
  color: #e0e0e0;
}

.dark-mode .message.system {
  background-color: #333;
  color: #bbb;
}

.dark-mode .chat-input {
  background-color: #2a2a2a;
  border-color: #444;
  color: #e0e0e0;
}

.dark-mode .send-button:hover {
  background-color: #004080;
}

.dark-mode .greeting {
    background: linear-gradient(90deg, #6a82fb, #fc5c7d); /* Same gradient for dark mode */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.dark-mode .sub-greeting {
    color: #e0e0e0; /* Light color for dark mode */
}

body.dark-mode .message.user {
    background-color: #131314;
  }
  
  body.dark-mode .message.assistant {
    background-color: #131314;
    color: #ffffff;
  }
  
  body.dark-mode .chat-input {
    background-color: #131314;
    color: #ffffff;
  }

  .send-button {
    color: #555;
  }

  .dark-mode .footer-text.text-muted {
    color: #d3d3d3 !important;
  }

/* Adjust other elements as needed */

/* Mobile Styles */
@media (max-width: 768px) {
  .button-container {
    flex-direction: column;
  }

  .button-card {
    width: 90%;
    margin: 10px auto;
  }

  .message-container-initial,
  .message-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .message {
    max-width: 100%;
    align-self: stretch;
  }

  .avatar {
    width: 25px;
    height: 25px;
  }

  .chat-input {
    font-size: 14px;
  }

  .greeting {
    font-size: 2.5rem;
  }

  .sub-greeting {
    font-size: 1.5rem;
  }

  .footer-text {
    font-size: 0.7rem;
  }

  .chat-window {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.5rem;
  }

  .input-footer-container {
    width: 95% !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .clear-chat-button {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 1000;
    border: none;
    border-radius: 50%;
    padding: 8px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
  }

  .search-container {
    height: auto;
    min-height: 100vh;
  }

  .main-content {
    flex-grow: 1;
  }

  .clear-chat-button {
    padding: 10px;
  }
}

.loading-message {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100%; /* Ensure it takes full height of the message container */
  padding-left: 10px; /* Add some padding if needed */
}

.clear-chat-button {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  background-color: rgba(240, 240, 240, 0.8);
  color: #333;
  border: none;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); */
}

.clear-chat-button:hover {
  background-color: rgba(220, 220, 220, 0.9);
  transform: scale(1.1);
}

.dark-mode .clear-chat-button {
  background-color: rgba(60, 60, 60, 0.8);
  color: #e0e0e0;
}

.dark-mode .clear-chat-button:hover {
  background-color: rgba(80, 80, 80, 0.9);
}
