/* Reset default styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Ensure root elements take full width and height */
html, body, #root {
  width: 100%;
  height: 100%;
  overflow: auto; /* Allow body scrolling */
}

/* Style for the main container */
.container-admin {
  width: 100%; /* Full width */
  height: 100vh; /* Full viewport height */
  display: flex; /* Use flexbox for layout */
  flex-direction: column; /* Stack children vertically */
  overflow-y: auto; /* Enable vertical scrolling for the container */
  padding-left: 8rem;
  padding-right: 8rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

/* File Upload Styles */
.dropzone {
  height: 200px;
  width: 100%;
  border: 2px dashed #131314 ;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #f8f9fa;
  transition: border-color 0.3s ease;
}

.dropzone:hover {
  border-color: #131314 ;
}

.file-list {
  max-height: 300px; /* Set a maximum height */
  overflow-y: auto; /* Enable vertical scrolling */
  margin-top: 20px; /* Add some spacing above */
  border: 1px solid #dee2e6; /* Optional: Add a border for better visibility */
  border-radius: 5px; /* Optional: Rounded corners */
  background-color: #f8f9fa; /* Optional: Background color */
}

.file-item {
  width: calc(25% - 20px);
  margin: 10px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.file-icon {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}

.file-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  color: #ffffff;
}

/* Card Styles */
.card {
  margin-bottom: 30px;
  border: none;
}

.card-header {
  background-color: #131314 ;
  color: #fff;
  font-weight: bold;
}

.card-body {
  padding: 20px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .container-admin {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .upload-documents-form {
    margin-top: 0.5rem;
  }

  .drag-drop-area {
    padding: 0.5rem;
    font-size: 0.9rem;
  }

  .file-list {
    max-height: 200px;
  }

  .document-list {
    padding: 1rem;
    max-height: 500px;
    min-height: 300px;
  }

  .sort-filter-controls {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .sort-filter-controls select,
  .search-input {
    width: 100%;
  }

  .reset-filters {
    width: 100%;
    margin-top: 0.5rem;
  }

  .document-table th,
  .document-table td {
    padding: 8px;
    font-size: 0.9rem;
  }

  .document-table th:nth-child(1),
  .document-table td:nth-child(1) {
    width: 40%;
  }

  .document-table th:nth-child(2),
  .document-table td:nth-child(2) {
    width: 35%;
  }

  .document-table th:nth-child(3),
  .document-table td:nth-child(3) {
    width: 25%;
  }

  .trash-icon,
  .download-icon {
    font-size: 1.2rem;
  }

  .greeting {
    font-size: 1.5rem;
  }

  .lead {
    font-size: 1rem;
  }

  h2 {
    font-size: 1.3rem;
  }

  .helper-text {
    font-size: 0.8rem;
  }

  .proceed-button {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
  }
}

/* Additional mobile-specific styles */
@media (max-width: 480px) {
  .container-admin {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .document-table th,
  .document-table td {
    padding: 6px;
    font-size: 0.8rem;
  }

  .trash-icon,
  .download-icon {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .file-item {
    width: calc(50% - 20px);
  }
}

@media (max-width: 480px) {
  .file-item {
    width: calc(100% - 20px);
  }
}

/* Additional styles for child elements */
.manage-assistant-outputs {
  flex: 1; /* Allow this section to grow and fill space */
  display: flex;
  flex-direction: column; /* Stack sliders vertically */
  justify-content: center; /* Center content vertically */
}

.manage-assistant-outputs > * {
  width: 100%;
}

/* Dark Mode Styles */
.dark-mode {
  background-color: #121212;
  color: #e0e0e0;
}

.dark-mode .container-admin {
  background-color: #1e1e1e;
}

.dark-mode .dropzone {
  background-color: #2c2c2c;
  border-color: #4a4a4a;
}

.dark-mode .dropzone:hover {
  border-color: #6a6a6a;
}

.dark-mode .file-item {
  background-color: #2c2c2c;
  border-color: #4a4a4a;
  box-shadow: 0 2px 4px rgba(255, 255, 255, 0.1);
}

.dark-mode .file-name {
  color: #e0e0e0;
}

.dark-mode .card {
  background-color: #2c2c2c;
  box-shadow: 0 4px 6px rgba(255, 255, 255, 0.1);
}

.dark-mode .card-header {
  background-color: #131314;
  color: #fff;
}

.dark-mode .card-body {
  color: #e0e0e0;
}

/* Additional Dark Mode Styles */
.dark-mode .error-message {
  color: #ff4d4d; /* Brighter red for errors */
}

.dark-mode .success-message {
  color: #66bb6a; /* Brighter green for success */
}

.dark-mode .search-input,
.dark-mode select {
  background-color: #2c2c2c;
  color: #e0e0e0;
  border: 1px solid #4a4a4a;
}

.dark-mode .search-input:focus,
.dark-mode select:focus {
  border-color: #007bff;
}

.dark-mode .document-table th {
  background-color: #1e1e1e;
}

.dark-mode .document-table th,
.dark-mode .document-table td {
  color: #e0e0e0
}

.dark-mode .document-table tr:nth-child(even) {
  background-color: #2c2c2c; /* Darker row background for even rows */
}

.dark-mode .document-table tr:nth-child(odd) {
  background-color: #1e1e1e; /* Darker row background for odd rows */
}

.dark-mode .highlight {
  background-color: #ffeb3b; /* Highlight color */
}

.upload-documents-form {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.drag-drop-area {
  border: 2px dashed #007bff;
  border-radius: 5px;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  color: #007bff;
  transition: background-color 0.3s, border-color 0.3s;
}

.drag-drop-area:hover {
  background-color: rgba(0, 123, 255, 0.1);
}

.disabled-drag-drop {
  background-color: #e0e0e0 !important; /* Light grey background for disabled state */
  border-color: #b0b0b0 !important; /* Grey border for disabled state */
  color: #a0a0a0 !important; /* Grey text color for disabled state */
  cursor: not-allowed !important; /* Change cursor to indicate it's not clickable */
  opacity: 0.6 !important; /* Slightly transparent to indicate disabled state */
}

/* Adjustments for Uploaded Documents UI */
.document-list {
  margin-top: 2rem;
  padding: 1.5rem; /* Increased padding for better spacing */
  border-radius: 8px; /* Rounded corners */
  background-color: #f8f9fa; /* Light background for contrast */
  max-height: 650px; /* Set a maximum height for the document list */
  min-height: 650px; /* Set a minimum height for the document list */
  overflow-y: auto; /* Enable vertical scrolling */
}

.sort-filter-controls {
  display: flex;
  align-items: center;
  gap: 2rem; /* Increased gap for better spacing */
  margin-bottom: 1.5rem; /* Added margin for spacing below */
}

.sort-filter-controls label {
  font-weight: bold; /* Bold labels for emphasis */
  color: #333; /* Darker color for better readability */
}

.reset-filters {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1.5rem; /* Increased padding */
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s; /* Added shadow transition */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Softer shadow */
}

.reset-filters:hover {
  background-color: #0056b3;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

.search-input {
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px; /* Added border radius */
  width: 100%; /* Full width */
  font-size: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.search-input:focus {
  border-color: #007bff;
  outline: none;
}

.document-table {
  width: 100%; /* Full width */
  border-collapse: separate; /* Remove space between cells */
  border-spacing: 0; /* Ensure consistent column widths */
}

.document-table th, .document-table td {
  padding: 10px; /* Add padding for better spacing */
  text-align: left; /* Align text to the left */
  border-bottom: 1px solid #dee2e6; /* Light border for separation */
}

.document-table th {
  background-color: #f8f9fa; /* Light background for headers */
  font-weight: bold; /* Bold headings for emphasis */
  position: sticky;
  top: 0;
  z-index: 10;
}

.document-table th:nth-child(1),
.document-table td:nth-child(1) {
  width: 50%; /* Adjust width for Name column */
}

.document-table th:nth-child(2),
.document-table td:nth-child(2) {
  width: 30%; /* Adjust width for Date column */
}

.document-table th:nth-child(3),
.document-table td:nth-child(3) {
  width: 20%; /* Adjust width for Actions column */
}

.document-table tr:hover {
  background-color: rgba(0, 123, 255, 0.1); /* Highlight row on hover */
}

.error-message {
  color: red;
  margin-bottom: 1rem;
  font-weight: bold; /* Bold error messages */
}

.success-message {
  color: green;
  margin-bottom: 1rem;
  font-weight: bold; /* Bold success messages */
}

.download-icon {
  color: #ffffff;
  font-size: 1rem;
}

.trash-icon {
  color: #ff4d4d; /* Red color for delete icon */
  font-size: 1.5rem; /* Slightly larger icon */
  transition: transform 0.2s ease, color 0.2s ease; /* Smooth transition for hover effects */
  cursor: pointer; /* Change cursor to pointer */
}

.trash-icon:hover {
  color: #d9534f; /* Darker red on hover */
  transform: scale(1.1); /* Slightly enlarge the icon on hover */
}

.trash-icon:active {
  transform: scale(0.95); /* Slightly shrink the icon on click */
}

.download-icon:hover {
  color: #007bff;
}

.trash-icon:hover {
  color: #ff4d4d;
}

.dark-mode .download-icon {
  color: #ffffff;
}

.dark-mode .trash-icon {
  color: #ff4d4d; /* Red color for delete icon in dark mode */
}

.dark-mode .trash-icon:hover {
  color: #d9534f; /* Darker red on hover in dark mode */
}

.dark-mode .document-table td button:hover {
  background-color: rgba(255, 77, 77, 0.2); /* Light red background on hover in dark mode */
}

/* Dark Mode Styles for Document List */
.dark-mode .document-list {
  background-color: #000000 !important; /* Dark background for document list */
  color: #e0e0e0; /* Light text color for better readability */
}

.dark-mode .document-table th {
  background-color: #2c2c2c; /* Darker background for table headers */
  color: #e0e0e0; /* Light text color for headers */
}

.dark-mode .document-table td {
  color: #e0e0e0; /* Light text color for table cells */
}

.dark-mode .error-message {
  color: #ff4d4d; /* Brighter red for errors in dark mode */
}

.dark-mode .success-message {
  color: #66bb6a; /* Brighter green for success in dark mode */
}

.dark-mode .search-input,
.dark-mode select {
  background-color: #2c2c2c; /* Dark background for inputs */
  color: #e0e0e0; /* Light text color for inputs */
  border: 1px solid #4a4a4a; /* Darker border for inputs */
}

.dark-mode .search-input:focus,
.dark-mode select:focus {
  border-color: #007bff; /* Highlight border on focus */
}

/* Additional styles for the delete button */
.document-table td button {
  background: none; /* No background */
  border: none; /* No border */
  cursor: pointer; /* Pointer cursor */
  padding: 0; /* No padding */
  margin: 0; /* No margin */
  transition: background-color 0.2s ease; /* Smooth transition for background color */
}

.document-table td button:hover {
  background-color: rgba(255, 77, 77, 0.1); /* Light red background on hover */
  border-radius: 5px; /* Rounded corners */
}

.dark-mode .sort-filter-controls label {
  color: #e0e0e0; /* Light text color for labels in dark mode */
}

.dark-mode .reset-filters {
  background-color: #007bff; /* Blue background for reset button in dark mode */
  color: white; /* White text color for reset button */
}

.dark-mode .reset-filters:hover {
  background-color: #0056b3; /* Darker blue on hover in dark mode */
}

/* Add animation for sync icon */
.sync-icon {
  transition: transform 0.5s ease; /* Smooth transition for scaling */
}

.sync-icon:active {
  transform: rotate(360deg) scale(0.9); /* Rotate and slightly shrink on click */
}

/* Additional click effect */
.sync-icon:focus {
  outline: none; /* Remove outline on focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Add a subtle glow effect */
}
/* Add this to your existing CSS file */
.table-container {
  max-height: 400px; /* Set a maximum height for the table container */
  overflow-y: auto; /* Enable vertical scrolling */
  border-radius: 5px; /* Optional: Rounded corners */
}

/* Adjustments for mobile-friendly layout */
@media (max-width: 768px) {
  .container-admin {
    padding-left: 2rem; /* Reduced padding for smaller screens */
    padding-right: 2rem; /* Reduced padding for smaller screens */
  }

  .dropzone {
    height: 150px; /* Reduced height for mobile */
  }

  .file-item {
    width: calc(100% - 20px); /* Full width for file items on mobile */
    margin: 5px; /* Reduced margin for file items */
  }

  .document-list {
    max-height: 900px; /* Set a smaller max height for mobile */
    padding: 1rem; /* Reduced padding for document list */
    min-height: 800px; /* Set a smaller min height for mobile */
  }

  .sort-filter-controls {
    flex-direction: column; /* Stack controls vertically */
    gap: 1rem; /* Adjusted gap for better spacing */
  }

  .search-input {
    margin-bottom: 0.5rem; /* Reduced margin for search input */
  }
}

/* Dropdown Styles */
.sort-filter-controls select {
  padding: 0.5rem; /* Add padding for better spacing */
  border: 1px solid #ccc; /* Light border */
  border-radius: 5px; /* Rounded corners */
  background-color: #fff; /* White background */
  color: #333; /* Dark text color */
  font-size: 16px; /* Font size */
  transition: border-color 0.3s; /* Smooth border color transition */
}

.sort-filter-controls select:focus {
  border-color: #007bff; /* Highlight border on focus */
  outline: none; /* Remove outline */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Add a subtle glow effect */
}

/* Additional styles for labels */
.sort-filter-controls label {
  margin-right: 0.5rem; /* Space between label and dropdown */
  font-weight: bold; /* Bold labels for emphasis */
  color: #333; /* Darker color for better readability */
}

.dark-mode .sort-filter-controls label {
  color: #e0e0e0; /* Light text color for labels in dark mode */
}

.dark-mode .sort-filter-controls select {
  background-color: #2c2c2c; /* Dark background for dropdowns in dark mode */
  color: #e0e0e0; /* Light text color for dropdowns in dark mode */
  border: 1px solid #4a4a4a; /* Darker border for dropdowns in dark mode */
}

.dark-mode .sort-filter-controls select:focus {
  border-color: #007bff; /* Highlight border on focus in dark mode */
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
