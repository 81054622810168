/* NotFound Styles */
/* Reset margin and padding for NotFoundPage only when the class is applied */
.not-found-page * {
    margin: 0 !important;
    padding: 0 !important;
    box-sizing: border-box !important; /* Ensure padding and border are included in element's total width and height */
}

 html, .not-found-page body {
    height: 100% !important; /* Ensure the body takes up the full height */
    width: 100% !important; /* Ensure the body takes up the full width */
}

/* Ensure the not-found-container takes full height */
.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Full viewport height */
    width: 100vw; /* Full viewport width */
    background-image: url('../assets/404.svg'); /* Path to your SVG file */
    background-size: contain; /* Ensure the entire image is visible */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent the image from repeating */
    color: white; /* Text color */
    text-align: center; /* Center text */
    position: relative; /* Position relative for absolute children */
    padding: 20px; /* Add some padding */
}

.not-found-container h1 {
    font-size: 2.5rem;
    margin-top: 20px; /* Add margin to the top */

}

.not-found-container p {
    font-size: 1.2rem;
    margin-top: 10px; /* Add margin to the top */
}

.not-found-button {
    margin-top: 20px; /* Space above the button */
    padding: 10px 20px; /* Button padding */
    background-color: #007bff; /* Bootstrap primary color */
    color: white; /* Text color */
    border: none; /* Remove border */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor */
    font-size: 1rem; /* Font size */
}

.not-found-button:hover {
    background-color: #0056b3; /* Darker shade on hover */
}

.not-found-button:active {
    background-color: #676767 !important; /* Darker shade on hover */
}

.not-found-button {
    background-color: #000000 !important; /* Darker shade on hover */
}

.not-found-text {
    position: absolute; /* Position relative to the container */
    top: 80%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust for center alignment */
    color: rgb(0, 0, 0); /* Text color */
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
    .not-found-container h1 {
        font-size: 1.8rem; /* Smaller font size for mobile */
    }

    .not-found-container p {
        font-size: 1rem; /* Smaller font size for mobile */
    }

    .not-found-button {
        padding: 8px 16px; /* Adjust button padding for mobile */
        font-size: 0.9rem; /* Smaller font size for mobile */
    }
}
