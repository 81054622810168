.custom-alert-modal,
.custom-confirm-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.custom-alert-content,
.custom-confirm-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 90%;
    width: 400px;
}

.custom-alert-buttons,
.custom-confirm-buttons {
    margin-top: 20px;
    text-align: center;
}

.custom-alert-button,
.custom-confirm-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 5px;
}

.custom-alert-button:hover,
.custom-confirm-button:hover {
    background-color: #0056b3;
}

/* Dark mode styles */
.dark .custom-alert-content,
.dark .custom-confirm-content {
    background-color: #333;
    color: white;
}

.dark .custom-alert-button,
.dark .custom-confirm-button {
    background-color: #0056b3;
}

.dark .custom-alert-button:hover,
.dark .custom-confirm-button:hover {
    background-color: #003d82;
}
