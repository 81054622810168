.container-analyser {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-height: 100vh;
  overflow-y: auto;
}

.analyser-selection {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.analyser-selection input[type="radio"] {
  margin-right: 0.5rem;
}

.proceed-button {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
}

.proceed-button:hover {
  background-color: #0056b3;
}

.proceed-button:focus {
  outline: 3px solid #ffcc00;
  outline-offset: 2px;
}

.proceed-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.file-upload {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.drag-drop-area {
  border: 2px dashed #007bff;
  border-radius: 5px;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  color: #007bff;
  transition: background-color 0.3s, border-color 0.3s;
}

.drag-drop-area:hover {
  background-color: rgba(0, 123, 255, 0.1);
}

.error-message, .success-message {
  font-size: 0.9rem;
  margin: 1rem 0;
  text-align: center;
}

.error-message {
  color: red;
}

.success-message {
  color: green;
  font-size: 1.1rem;
}

.loading-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}

.spinner {
  border: 4px solid rgba(0, 123, 255, 0.3);
  border-top: 4px solid #007bff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.progress-indicator {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.step {
  flex: 1;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: white;
  border: 2px solid #b7b7b7;
  margin-bottom: 0.5rem;
}

.step.completed span, .step.active span {
  background-color: #007bff;
  border-color: #007bff;
  color: white;
}

.file-list {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #f0f0f0;
  border-radius: 5px;
}

.file-list ul {
  list-style-type: none;
  padding: 0;
}

.file-list li {
  padding: 0.5rem;
  border-bottom: 1px solid #ccc;
}

.file-list li:last-child {
  border-bottom: none;
}

.remove-button {
  margin-left: 10px;
  background-color: transparent;
  color: red;
  border: none;
  cursor: pointer;
  font-size: 0.9rem;
}

.remove-button:hover {
  text-decoration: underline;
}

.remove-button:disabled {
  color: #a0a0a0;
  cursor: not-allowed;
}

.help-icon {
  display: inline-block;
  margin-left: 5px;
  font-size: 0.9rem;
  color: #007bff;
  cursor: pointer;
  position: relative;
}

.tooltip {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  padding: 5px;
  z-index: 10;
  transition: opacity 0.2s;
}

.help-icon:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.cta-message {
  margin-top: 2rem;
  padding: 1.5rem;
  background-color: #f9f9f9;
  border: 1px solid #007bff;
  border-radius: 5px;
  text-align: center;
}

.cta-message button {
  margin: 0.5rem;
  padding: 0.75rem 1.5rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cta-message button:hover {
  background-color: #0056b3;
}

.disabled-drag-drop {
  background-color: #e0e0e0 !important; /* Light grey background for disabled state */
  border-color: #b0b0b0 !important; /* Grey border for disabled state */
  color: #a0a0a0 !important; /* Grey text color for disabled state */
  cursor: not-allowed !important; /* Change cursor to indicate it's not clickable */
  opacity: 0.6 !important; /* Slightly transparent to indicate disabled state */
}

.file-list.disabled {
  opacity: 0.6; /* Slightly transparent to indicate disabled state */
  pointer-events: none; /* Prevent interaction */
}

.analyser-option {
  padding: 0.5rem;
}

/* Dark Mode Styles */
.dark-mode .container-analyser {
  background-color: #121212;
  color: #e0e0e0;
}

.dark-mode .drag-drop-area {
  background-color: #1e1e1e;
  border-color: #007bff;
}

.dark-mode .drag-drop-area:hover {
  background-color: rgba(0, 123, 255, 0.2);
}

.dark-mode .file-list {
  background-color: #2c2c2c;
}

.dark-mode .file-list li {
  background-color: #2c2c2c;
  color: #e0e0e0;
}

.dark-mode .error-message {
  color: #ff4d4d;
}

.dark-mode .success-message {
  color: #66bb6a;
}

.dark-mode .tooltip {
  background: #1e1e1e;
  color: #e0e0e0;
}

.dark-mode .cta-message {
  background-color: #1e1e1e;
  border-color: #007bff;
}

.dark-mode .proceed-button {
  background-color: #007bff;
  color: white;
}

.dark-mode .proceed-button:hover {
  background-color: #0056b3;
}

.dark-mode .proceed-button:disabled,
.dark-mode .drag-drop-area.disabled,
.dark-mode .drag-drop-area:disabled {
  background-color: #444;
  color: #1e1e1e;
  cursor: not-allowed;
  opacity: 0.6;
}

.dark-mode .step span {
  background-color: #333;
  border-color: #555;
}

.dark-mode .step.completed span {
  background-color: #007bff;
  border-color: #007bff;
}

.dark-mode .form-text.text-muted {
  color: #e0e0e0 !important;
}


/* Media Queries for Responsive Design */
@media (min-width: 768px) {
  .container-analyser {
    padding: 2rem 10rem;
    padding-top: 4rem;
    padding-bottom: 8rem;
  }

  .proceed-button {
    width: auto;
  }
}
