/* UnauthorizedPage.css */

.unauthorized-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  background-image: url('../assets/Access_Denied.svg'); /* Replace with your SVG path */
  background-size: cover; /* Cover the entire container */
  background-position: center; /* Center the background image */
  color: white; /* Text color */
  text-align: center; /* Center text */
}

.unauthorized-content {
  background: rgba(0, 0, 0, 0.5); /* Optional: semi-transparent background for better text visibility */
  padding: 20px;
  border-radius: 8px; /* Rounded corners */
}

.unauthorized-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff; /* Button color */
  color: white; /* Button text color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.unauthorized-button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}
