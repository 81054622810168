/* Login Page Styles */
.container-login {
    display: flex;
    flex-direction: column; /* Stack children vertically */
    justify-content: center; /* Center vertically */
    align-items: center; /* Center horizontally */
    height: 100vh; /* Full viewport height */
    width: 100%; /* Ensure full width */
    padding: 0 20px; /* Add horizontal padding */
    box-sizing: border-box; /* Include padding in width calculation */
    margin: 0;
    padding-top: 0;
  }

  .login-container {
    width: 400px;
    margin: 0;
    padding-top: 0;
  }
  
  .logo-login {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .login-form {
    background-color: #fff;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 28px;
  }
  
  .login-form h2 {
    margin-bottom: 20px;
  }
  
  .login-form .form-control {
    margin-bottom: 10px;
  }

  /* Center the login page */
.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    margin-left: 0px !important;
  }


.logo-image {
    width: 100px;
    height: auto;
  }
  
  .btn-curved {
    border-radius: 20px !important;
  }

/* Dark Mode Styles */
body.dark-mode {
  background-color: #1a1a1a;
  color: #ffffff;
  --background-color: #1a1a1a;
  --text-color: #ffffff;
  --link-color: #0056b3;
}

body.dark-mode .login-form {
  background-color: #333333;
  border-color: #444444;
}

body.dark-mode .login-form h2 {
  color: #ffffff;
}

body.dark-mode .form-control {
  background-color: #444444;
  border-color: #555555;
  color: #ffffff;
}

body.dark-mode .form-control:focus {
  background-color: #555555;
  border-color: #666666;
}

body.dark-mode .btn-primary {
  background-color: #0056b3;
  border-color: #004085;
}

body.dark-mode .btn-primary:hover {
  background-color: #003d80;
  border-color: #002752;
}

body.dark-mode .logo-image {
  filter: brightness(0.8) contrast(1.2);
}

/* Add this to your CSS file */
@media (max-width: 768px) {
  .nav-content {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items */
  }

  .nav-link {
    padding: 10px; /* Increase padding for touch targets */
    font-size: 16px; /* Adjust font size for readability */
    color: var(--link-color);
  }

  .nav-link:hover {
    color: var(--link-color);
    text-decoration: underline;
  }

  .container-login {
    width: 90%; /* Make login container responsive */
  }

  .login-container {
    width: 100%; /* Make login container responsive */
    padding: 20px; /* Add padding for better spacing */
  }

  .logo-login {
    margin-bottom: 30px; /* Increase margin for better spacing */
  }

  .btn-curved {
    width: 100%; /* Full width for buttons on mobile */
    padding: 15px; /* Increase padding for touch targets */
  }
}